import React from 'react'
// import { Link } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import SEO from '../components/seo'

const EA = ({ children, ...props }) => {
  return (
    <a {...props} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

const IndexPage = props => {
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div css={content}>
        <div css={section}>
          <h1>Welcome, friend!</h1>
          <p>
            We are not currently accepting new projects, but if you'd like to
            talk to us anyway, reach out to hello@shenans.co and introduce
            yourself!
          </p>
          {/* <p>
            At Cooperative Shenanigans, we are interested in exploring new ways
            for people to work and play together through creative use of technology.
          </p>
          <p>If you're a game designer interested in using technology to enhance your idea, or you  want help with streamlining your design and testing process from experienced technologists who know your pain, you've come to the right place.</p> */}
        </div>
        {/* <div css={section}>
          <h1>Why hire Cooperative Shenanigans?</h1>
          <h2>Cooperative</h2>
          <p>
            You need help, but you don’t want to hire just anyone. You want to
            work with someone who will take the time to{' '}
            <strong>understand your vision</strong>, someone who will{' '}
            <strong>listen to what’s important to you</strong>, but isn’t afraid
            to challenge you to get out of your comfort zone and make your idea
            the best it can be.
          </p>
          <p>
            You’re not looking for someone who just follows instructions to the
            letter - if you could give instructions that detailed, you’d do the
            job yourself! Instead, you want a partner. Someone with the
            technical and creative experience to <strong>work with you</strong>{' '}
            to turn your dream into a plan - and then into reality.
          </p>
          <h2>Shenanigans</h2>
          <p>
            Your idea is creative, innovative, and fun. This project is your
            dream, your imagination, your story made manifest. You are willing
            to put in the money, time, and effort to make your project real, but
            it’s not just a business transaction... you want to work with people
            who care about doing good work.
          </p>
          <p>
            You want to be able to <strong>trust</strong> that the people
            helping you make your idea into a reality will treat it with as much
            respect, creativity, and attention to detail as you do.
          </p>
          <p>
            If this resonates with you and you're ready to get started, reach
            out to us at{' '}
            <EA href="mailto:hello@shenans.co">hello@shenans.co</EA> and
            introduce yourself!
          </p>
          <p>
            If you're still not sure, have a look at our previous work below:
          </p>
        </div> */}
        <div css={section}>
          <h2>About Us</h2>
          <p>
            We are game designers and software engineers with a focus on
            storytelling, education, thematic mechanics, and creating technology
            to enhance human experience.
          </p>
          <h2>Our Work</h2>
          <p>
            We create board games as part of{' '}
            <EA href="https://quixoticgames.com">Quixotic Games</EA>.
          </p>
          <p>
            We created the{' '}
            <EA href="https://app.quixoticgames.com">
              Dungeon Alliance Campaign Tracker
            </EA>{' '}
            app.
          </p>
          <p>
            We created{' '}
            <EA href="http://dndaw.com">Burning Ballista, Swooping Dragon</EA>{' '}
            and the <EA href="https://build.dndaw.com">Legion Builder</EA>
          </p>
        </div>
        <div css={section}>
          <p>The core Shenans team is Cat and Maka.</p>
          <p>
            <strong>Cat</strong> is a Software Engineer with over 20 years of experience in acedemic, corporate, and startup environments.
            She was the Chief Artisan (Game Designer) for{' '}
            <EA href="https://codecombat.com">CodeCombat</EA>, where she helped
            drive the core game and educational design, including creation of
            600 game levels that teach students how to code.
          </p>
          <p>
            <strong>Maka</strong> is the Assistant Director of Game Research and
            Immersive Design (GRID) at the Rutgers Division of Continuing
            Sutdies, and teaches Game Design at Raritan Valley Community
            College. He writes about board games and other topics{' '}
            <EA href="https://gradin.zdh.com/">on his blog</EA>.
          </p>
        </div>
      </div>
    </Layout>
  )
}

const content = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  --hex-width: 400px;
`

const section = css`
  width: 90%;
  color: #efefec;
  /* background-color: rgba(111, 118, 173, .7); */
  background-color: rgba(20, 32, 80, 0.9);
  border-radius: 5px;
  padding: 5rem;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  line-height: 1.6;

  @media (max-width: 420px) {
    width: 95%;
    padding: 2.5rem;
  }

  a {
    text-decoration: underline;
  }
`

export default IndexPage
